import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout/index";
// import * as Icons from "../../icons";
import "./News.scss";

const NewsComponent = () => {
  return (
    <Layout>
      <div className="newsContainer">
        <div className="news">
          <div className="news__header">
            <h1>
              Read about : <span>News</span>{" "}
            </h1>
          </div>

          <div className="news__body">
            <div className="news-list">
              <div className="news-list__layout-1">
                  layout one
              </div>

              <div className="news-list__layout-2">
                  layout one
              </div>

              <div className="news-list__layout-3">
                  layout one
              </div>
            </div>
        </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsComponent;
